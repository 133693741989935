import React from "react";
import {
  ImageField,
  TextField,
  Edit as BaseEdit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  ImageInput,
  NumberInput,
  required,
  DateField,
  SimpleShowLayout,
} from "react-admin";
import { useSearchParams } from "react-router-dom";
// const styles = {
//   inlineBlock: { display: "inline-flex", marginRight: "1rem" },
// };

export const Edit = ({ classes, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <BaseEdit
      {...props}
      redirect={(resource, id, data, state) => {
        const redirectParam = searchParams.get("redirect")
          ? searchParams.get("redirect") + "?date=" + data.startDate
          : null;
        return redirectParam ?? "unavailability";
      }}
    >
      <SimpleForm>
        <SimpleShowLayout>
          <TextField source="employee.fullName" label="Empleado" />
          <DateField source="startDate" label="Fecha de Inicio" showTime />
          <DateField source="endDate" label="Fecha Final" showTime />
          <DateField source="deletedAt" label="Borrado" />
        </SimpleShowLayout>
        <br />
        <TextInput source="reason" label="Detalle" />
      </SimpleForm>
    </BaseEdit>
  );
};
