import React from "react";
import {
  Filter,
  List as BaseList,
  Datagrid,
  TextField,
  DateInput,
  DateField,
} from "react-admin";

const UnavailabilityFilter = (props) => {
  return (
    <Filter {...props}>
      <DateInput label="Desde" source="startDate>" />
      <DateInput label="Hasta" source="startDate<" />
    </Filter>
  );
};

export const List = (props) => (
  <BaseList
    sort={{ field: "startDate", order: "ASC" }}
    filters={<UnavailabilityFilter />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField
        source="startDate"
        label="Fecha de Inicio"
        showTime
        locales="es-ES"
      />
      <DateField
        source="endDate"
        label="Fecha Final"
        showTime
        locales="es-ES"
      />
      <TextField source="employee.fullName" label="Empleado" />
      <DateField source="deletedAt" label="Borrado" showTime locales="es-ES" />
    </Datagrid>
  </BaseList>
);
