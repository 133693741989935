import React from "react";
import {
  ImageField,
  TextInput,
  Filter,
  List as BaseList,
  Datagrid,
  TextField,
  BooleanField,
} from "react-admin";

const TitleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Título" source="title" alwaysOn />
  </Filter>
);

export const List = (props) => (
  <BaseList filters={<TitleFilter />} {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <ImageField source="imageURL" label="Imagen" />
      <TextField source="unitPrice" label="Precio Unitario(Bs.)" />
      <TextField source="description" label="Descripción" />
      <BooleanField source="enabled" label="Habilitado" />
      <BooleanField source="qrEnabled" label="Pago con QR habilitado" />
      <BooleanField
        source="cardPaymentEnabled"
        label="Pago con Tarjeta/Tigo Money habilitado"
      />
       <BooleanField source="variablePrice" label="Precio Variable" />
       <TextField source="method" label="Método" />
    </Datagrid>
  </BaseList>
);
