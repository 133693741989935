import React, { useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SelectInput,
  useChoicesContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import Map from "../components/Map";

export const SelectCustomerInput = (props) => {
  const { watch } = useFormContext();
  const customer = watch("customer");
  const address = watch("address");
  const isInBranch = useFormContext().watch("isInBranch");
  return (
    <>
      <ReferenceInput
        label="Cliente"
        source="customer"
        reference="enterprise/customers"
        // filterToQuery={(text) => ({ fullName: [`/${text}/i`] })}
      >
        <AutocompleteCustomer />
      </ReferenceInput>
      {!isInBranch && (
        <SelectInput
          validate={[required()]}
          source="address"
          label="Dirección"
          choices={customer?.addresses || []}
          disabled={!!!customer}
          optionText={(value) => value?.name ?? value?.street}
          optionValue="_id"
          parse={(value) => {
            return customer?.addresses.find((e) => e?._id === value);
          }}
          format={(value) => value?.name ?? value?.street}
        />
      )}
      {address && (
        <Map
          source={"address.coordinates"}
          initialLatitude={address?.coordinates?.latitude}
          initialLongitude={address?.coordinates?.longitude}
          disabled
        />
      )}
    </>
  );
};

const AutocompleteCustomer = () => {
  const choicesContext = useChoicesContext();
  return (
    <AutocompleteInput
      validate={[required()]}
      source="customer"
      label="Cliente"
      optionText="fullName"
      parse={(value) => {
        return choicesContext.allChoices.find((e) => e._id === value);
      }}
      clearOnBlur={false}
      filterToQuery={(text) => ({
        filter: JSON.stringify({
          $or: [
            { fullName: { $regex: text, $options: "i" } },
            { phone: { $regex: text, $options: "i" } },
          ],
        }),
      })}
    />
  );
};
