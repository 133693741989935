import React, { useEffect, useState } from "react";
import {
  Create as BaseCreate,
  SimpleForm,
  SelectInput,
  required,
  useGetOne,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  useChoicesContext,
  NumberInput,
  useCreate,
  BooleanInput,
  TextInput,
  useNotify,
  Button,
  useDataProvider,
} from "react-admin";
import moment from "moment";
import { es } from "date-fns/locale";
import { useFormContext, useWatch } from "react-hook-form";
import { SelectCustomerInput } from "./SelectCustomer";
import { DateInput } from "../components/DatePicker";
import { toHoursAndMinutes } from "../util";
import TextField from "@mui/material/TextField";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

const AvailableDateInput = ({ availability, ...props }) => {
  return (
    <DateInput
      validate={[required()]}
      source="date"
      label="Fecha de reserva"
      inputVariant="outlined"
      options={{
        format: "dd/MM/YYY",
        clearable: true,
        disablePast: true,
      }}
      providerOptions={{ locale: es }}
      shouldDisableDate={(day) => {
        const date = moment(day);
        return (
          date < moment().startOf("day") ||
          availability[date.isoWeekday() - 1].length === 0
        );
      }}
      {...props}
    />
  );
};

const HourInput = ({ availability }) => {
  const { watch, setValue } = useFormContext();
  const date = watch("date");
  const editedEstimatedWorkMinutes = watch("editedEstimatedWorkMinutes");
  const [hourPicker, setHourPicker] = useState([]);
  const serviceCart = useFormContext().watch("serviceCart");
  const totalEstimatedWorkMinutes =
    editedEstimatedWorkMinutes ??
    serviceCart?.reduce(
      (a, b) =>
        a?.unitEstimatedWorkMinutes * a?.quantity ||
        a + b?.unitEstimatedWorkMinutes * b?.quantity,
      0
    ) ??
    0;
  useEffect(() => {
    _setHourPicker(moment(date));
    setValue("hour", "");
  }, [date, totalEstimatedWorkMinutes]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("date")) {
      setValue("date", new Date(searchParams.get("date")));
    }
  }, [searchParams.get("date")]);

  const [fetchAvailability, { isLoading, error }] = useCreate();

  const _setHourPicker = async (date) => {
    let array = [];
    let today = moment();
    let isSameDay = moment(today).isSame(date, "day");
    await fetchAvailability(
      "dateAvailability/" + JSON.parse(localStorage.getItem("profile"))._id,
      {
        data: {
          date: date,
          estimatedTime: totalEstimatedWorkMinutes,
          isInBranch: watch("isInBranch"),
          branch: watch("branch")?._id,
          employee: watch("employee")?._id,
          serviceCart: serviceCart?.map((e) => e._id) ?? [],
        },
      },
      {
        onSuccess: (response) => {
          response.data.availability.map((e) => {
            const _date = moment(e);
            if (isSameDay && _date.hours() <= today.hours()) {
              return;
            }
            array.push({
              id: _date.hours() + _date.minutes() / 60,
              name: _date.format("HH:mm"),
            });
          });
          setHourPicker(array);
        },
      }
    );
  };

  return (
    <SelectInput
      validate={[required()]}
      source="hour"
      label="Hora"
      choices={hourPicker}
      disabled={hourPicker.length === 0 || serviceCart.length === 0}
    />
  );
};

export const Create = (props) => {
  const initAvailability = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  const [availability, setAvailability] = useState(initAvailability);

  const transform = (data) => {
    const provider = JSON.parse(localStorage.getItem("profile"));
    const booking = {
      ...data,
      serviceCart: data.serviceCart.map((e) => {
        return {
          service: e,
          quantity: e.quantity,
          price: e.quantity * e.unitPrice,
          estimatedWorkMinutes: e.quantity * e.unitEstimatedWorkMinutes,
        };
      }),
      paymentInfo: {
        totalPrice:
          (1 - (data.discountPercentage || 0) / 100) *
            data.serviceCart?.reduce(
              (a, b) =>
                a.unitPrice * a.quantity ||
                a + (b.unitPrice || 0) * (b.quantity || 0),
              0
            ) -
            data.discountAmount || 0,
        paymentMethod: "Efectivo",
        couponCode: data.paymentInfo?.couponCode,
      },
      totalEstimatedWorkMinutes:
        data.editedEstimatedWorkMinutes ??
        data.serviceCart?.reduce(
          (a, b) =>
            a.unitEstimatedWorkMinutes * a.quantity ||
            a + (b.unitEstimatedWorkMinutes || 0) * (b.quantity || 0),
          0
        ),
      bookingDate: moment(data.date)
        .hours(data.hour)
        .minutes((data.hour % 1) * 60)
        .format(),
      customer: {
        ...data.customer,
        address: data.address,
      },
      status: "Confirmado",
      provider: {
        ...provider,
        name: provider.first_name,
        logoURL: provider.picture,
      },
    };
    if (data.employee) {
      booking.employee = {
        ...data.employee,
        fullName: data.employee.first_name + " " + data.employee.last_name,
        photoURL: data.employee.picture,
        CI: data.employee.CI_NIT,
        pushToken: data.employee.pushToken,
      };
    }
    return booking;
  };

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <BaseCreate
      {...props}
      transform={transform}
      redirect={(resource, id, data, state) => {
        const branch = data.branch?._id ?? null;
        let redirectParam = searchParams.get("redirect")
          ? searchParams.get("redirect") +
            "?date=" +
            data.date +
            "&employee=" +
            data.employee?._id +
            "&serviceMethod=" +
            searchParams.get("serviceMethod")
          : null;
        if (redirectParam && branch) {
          redirectParam += "&branch=" + branch;
        }
        return redirectParam ?? "booking";
      }}
    >
      <SimpleForm>
        <BooleanInput
          source="isInBranch"
          defaultValue={
            searchParams.get("serviceMethod") !== "A domicilio" ?? false
          }
          label="¿Servicio en Sucursal?"
        />
        <ReferenceInput
          label="Sucursal"
          source="branch"
          reference="enterprise/branches"
          filter={{
            status: true,
          }}
        >
          <SelectBranchInput />
        </ReferenceInput>
        <SelectCustomerInput />
        <ArrayInput
          validate={[required()]}
          source="serviceCart"
          label="CARRITO"
        >
          <SimpleFormIterator disableReordering>
            <ReferenceInput reference="service">
              <SelectServiceInput />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TotalFields />
        <ReferenceEmployee
          setAvailability={setAvailability}
          initAvailability={initAvailability}
        />
        <AvailableDateInput availability={availability} />
        <br />
        <HourInput availability={availability} />

        <TextInput
          source="notes"
          label="Detalles"
          multiline
          fullWidth
          rows={4}
        />
      </SimpleForm>
    </BaseCreate>
  );
};

const SelectEmployeeInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const date = useFormContext().watch("date");
  const hour = useFormContext().watch("hour");
  const branch = useFormContext().watch("branch");
  const isInBranch = useFormContext().watch("isInBranch");
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <SelectInput
      {...props}
      label="Empleado"
      optionText={(value) => {
        return value.first_name + " " + value.last_name;
      }}
      optionValue="_id"
      // disabled={!date || !hour}
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
      source={choicesContext.source}
      choices={
        isInBranch
          ? choicesContext.allChoices?.filter((e) => {
              return e.branch?._id === branch?._id;
            })
          : choicesContext.allChoices?.filter((e) => {
              return e.serviceMethod !== "En sucursal";
            })
      }
      defaultValue={choicesContext?.allChoices.find(
        (e) => e._id === searchParams.get("employee")
      )}
    />
  );
};

const ReferenceEmployee = ({ setAvailability, initAvailability }) => {
  const date = useFormContext().watch("date");
  const hour = useFormContext().watch("hour");
  const serviceCart = useFormContext().watch("serviceCart");
  const isInBranch = useFormContext().watch("isInBranch");
  const branch = useFormContext().watch("branch");
  const [totalEstimatedWorkMinutes, setTotalEstimatedWorkMinutes] = useState(0);
  const [endDate, setEndDate] = useState(moment().format());
  const [bookingDate, setBookingDate] = useState(null);
  const editedEstimatedWorkMinutes = useFormContext().watch(
    "editedEstimatedWorkMinutes"
  );

  const employee = useFormContext().watch("employee");

  const {
    data: user,
    isLoading,
    error,
  } = useGetOne("availability", {
    id: employee?._id ?? JSON.parse(localStorage.getItem("profile"))._id,
    meta: { branch: isInBranch ? branch?._id : null },
  });

  useEffect(() => {
    if (user?.availability) {
      const _availability = user?.availability?.reduce((array, e) => {
        return {
          ...array,
          [e.dayIndex]: [...array[e.dayIndex], e],
        };
      }, initAvailability);
      setAvailability(_availability);
    }
  }, [user]);

  useEffect(() => {
    if (date && hour) {
      setBookingDate(
        moment(date)
          .hours(hour)
          .minutes((hour % 1) * 60)
          .format()
      );
    }
  }, [date, hour]);
  useEffect(() => {
    if (serviceCart) {
      setTotalEstimatedWorkMinutes(
        editedEstimatedWorkMinutes ??
          serviceCart?.reduce(
            (a, b) =>
              a.unitEstimatedWorkMinutes * a.quantity ||
              a + b.unitEstimatedWorkMinutes * b.quantity,
            0
          )
      );
    }
  }, [JSON.stringify(serviceCart), editedEstimatedWorkMinutes]);

  useEffect(() => {
    setEndDate(
      moment(bookingDate).add(totalEstimatedWorkMinutes, "minutes").format()
    );
  }, [totalEstimatedWorkMinutes, bookingDate]);

  const providerId = JSON.parse(localStorage.getItem("profile"))._id;

  return (
    <ReferenceInput
      label="Empleado"
      source="employee"
      reference={"employee/provider/" + providerId}
      filter={{
        // startDate: bookingDate,
        // endDate: endDate,
        idProv: providerId,
        state: true,
        serviceCart: serviceCart?.map((e) => e._id) ?? [],
        method: isInBranch ? "En sucursal" : "A domicilio",
      }}
    >
      <SelectEmployeeInput />
    </ReferenceInput>
  );
};

const SelectServiceInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const isInBranch = useFormContext().watch("isInBranch");
  const branch = useFormContext().watch("branch");
  const service = useWatch({ name: choicesContext.source });

  return (
    <React.Fragment>
      <SelectInput
        {...props}
        label="Servicio"
        optionText="name"
        parse={(value) => {
          return {
            ...choicesContext?.allChoices.find((e) => e._id === value),
            quantity: service.quantity || 1,
          };
        }}
        format={(value) => {
          return value.name;
        }}
        source={choicesContext.source}
        choices={
          isInBranch
            ? choicesContext.allChoices?.filter((e) => {
                return branch?.services?.includes(e._id);
              })
            : choicesContext.allChoices
        }
      />
      <NumberInput
        source={choicesContext.source + ".quantity"}
        step={1}
        defaultValue={1}
        label="Cantidad"
      />

      <Box sx={{ "& div": { m: 1 } }}>
        <TextField
          disabled
          defaultValue={0}
          value={service.unitPrice}
          label="Precio Unitario (Bs.)"
        />
        <TextField
          disabled
          defaultValue={0}
          value={service.unitPrice * service.quantity || 0}
          label="Subtotal (Bs.)"
        />
      </Box>
      <br />
      <Box sx={{ "& div": { m: 1 } }}>
        <TextField
          disabled
          defaultValue="0:00"
          value={toHoursAndMinutes(service.unitEstimatedWorkMinutes || 0)}
          label="Tiempo Estimado Unitario (h)"
        />
        <TextField
          disabled
          defaultValue={0}
          value={toHoursAndMinutes(
            service.unitEstimatedWorkMinutes * service.quantity || 0
          )}
          label="Subtotal (h)"
        />
      </Box>
      <br />
    </React.Fragment>
  );
};

const TotalFields = () => {
  const serviceCart = useFormContext().watch("serviceCart");
  const { watch, setValue } = useFormContext();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleVerifyCoupon = () => {
    const serviceCartToSend = serviceCart.map((service) => service._id);

    const bodyData = {
      code: couponCode,
      services: serviceCartToSend,
      customerId: watch("customer")?._id,
    };
    dataProvider
      .create("coupon/verify", { data: bodyData })
      .then(({ data }) => {
        if (data.valid) {
          setValue("paymentInfo.couponCode", data.coupon.code);
          if (data.coupon.discountType == "Porcentaje") {
            setDiscountPercentage(data.coupon.discount || 0);
            setValue("discountPercentage", data.coupon.discount || 0);
            setValue("discountAmount", 0);
            setDiscountAmount(0);
          } else {
            setDiscountAmount(data.coupon.discount || 0);
            setValue("discountAmount", data.coupon.discount || 0);
            setValue("discountPercentage", 0);
            setDiscountPercentage(0);
          }
        } else {
          notify("Cupón inválido o ya usado.", "warning");
        }
      })
      .catch((error) => {
        notify("Cupón no válido.", "error");
      });

    setOpenDialog(false);
  };

  useEffect(() => {
    if (serviceCart) {
      setValue(
        "editedEstimatedWorkMinutes",
        serviceCart?.reduce(
          (a, b) =>
            a.unitEstimatedWorkMinutes * a.quantity ||
            a + (b.unitEstimatedWorkMinutes || 0) * (b.quantity || 0),
          0
        ) || 0
      );
    }
  }, [JSON.stringify(serviceCart)]);

  return (
    <>
      <Box sx={{ "& div": { m: 1 }, display: "flex" }}>
        <TextField
          label="Código de Cupón"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <Button onClick={() => setOpenDialog(true)}>Aplicar Cupón</Button>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Aplicar Cupón</DialogTitle>
        <DialogContent>
          ¿Desea verificar y aplicar el descuento del cupón {couponCode}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVerifyCoupon} color="primary">
            Verificar
          </Button>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ "& div": { m: 1 } }}>
        <NumberInput
          source="discountAmount"
          disabled={!!discountPercentage}
          min={0}
          onChange={(e) => {
            setDiscountAmount(Number(e.target.value));
          }}
          value={discountAmount}
          label="Descuento (Bs.)"
        />

        <NumberInput
          source="discountPercentage"
          disabled={!!discountAmount}
          min={0}
          onChange={(e) => {
            setDiscountPercentage(Number(e.target.value));
          }}
          label="Descuento (%)"
        />

        <TextField
          disabled
          value={_.clamp(
            (1 - discountPercentage / 100) *
              serviceCart?.reduce(
                (a, b) =>
                  a.unitPrice * a.quantity ||
                  a + (b.unitPrice || 0) * (b.quantity || 0),
                0
              ) -
              discountAmount || 0,
            0,
            Number.MAX_VALUE
          )}
          label="Precio Total (Bs.)"
        />
        <SelectInput
          validate={[required()]}
          source="editedEstimatedWorkMinutes"
          label="Tiempo de servicio estimado"
          choices={[
            { id: 0, name: "0 Minutos" },
            { id: 30, name: "30 Minutos" },
            { id: 60, name: "1 Hora" },
            { id: 90, name: "1 Hora 30 Minutos" },
            { id: 120, name: "2 Horas" },
            { id: 150, name: "2 Horas 30 Minutos" },
            { id: 180, name: "3 Horas" },
            { id: 210, name: "3 Horas 30 Minutos" },
            { id: 240, name: "4 Horas" },
            { id: 270, name: "4 Horas 30 Minutos" },
            { id: 300, name: "5 Horas" },
            { id: 330, name: "5 Horas 30 Minutos" },
            { id: 360, name: "6 Horas" },
            { id: 390, name: "6 Horas 30 Minutos" },
            { id: 420, name: "7 Horas" },
            { id: 450, name: "7 Horas 30 Minutos" },
            { id: 480, name: "8 Horas" },
          ]}
        />
      </Box>
    </>
  );
};

const SelectBranchInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const isInBranch = useFormContext().watch("isInBranch");
  const [searchParams, setSearchParams] = useSearchParams();
  return isInBranch ? (
    <SelectInput
      {...props}
      label="Sucursal"
      optionText={(value) => {
        return value.name;
      }}
      optionValue="_id"
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
      validate={[required()]}
      defaultValue={choicesContext?.allChoices.find(
        (e) => e._id === searchParams.get("branch")
      )}
    />
  ) : (
    <React.Fragment />
  );
};
