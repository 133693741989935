import React from "react";
import { Filter, List as BaseList, Datagrid, TextField, DateField, BooleanField } from "react-admin";
import RegexSearchInput from "../../components/RegexSearchInput";

const NameFilter = (props) => (
  <Filter {...props}>
    <RegexSearchInput source="name" label="Nombre"  />
  </Filter>
);

export const List = (props) => (
  <BaseList {...props} filters={<NameFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <TextField source="addressInfo.phone" label="Teléfono" />
      <TextField source="addressInfo.street" label="Dirección" /> 
      <BooleanField source="status" label="Estado" /> 
    </Datagrid>
  </BaseList>
);
