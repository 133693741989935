import {
  DashboardMenuItem,
  Menu,
  ResourceMenuItem,
  useResourceDefinitions,
} from "react-admin";
import LabelIcon from "@mui/icons-material/Label";

export const CustomMenu = () => {
  const resources = useResourceDefinitions();
  return (
    <Menu>
      <DashboardMenuItem key="default-dashboard-menu-item" />
      {Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => (
          <ResourceMenuItem key={name} name={name} />
        ))}
      <Menu.Item
        to="/tutorial"
        primaryText="Tutorial"
        leftIcon={<LabelIcon />}
      />
    </Menu>
  );
};
