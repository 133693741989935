import React from "react";
import { styled } from '@mui/material/styles';
import {
  Create as BaseCreate,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  ArrayInput,
  NumberInput,
  required,
  email,
  DateInput,
  SelectInput,
} from "react-admin";
import Map from "../../components/Map";
const PREFIX = 'Create';

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`
};

const StyledBaseCreate = styled(BaseCreate)({
  [`& .${classes.inlineBlock}`]: { display: "inline-flex", marginRight: "1rem" },
});

export const Create = (({  ...props }) => (
  <StyledBaseCreate {...props}>
    <SimpleForm>
      <TextInput
        validate={[required()]}
        source="fullName"
        label="Nombre Completo"
      />
      <NumberInput source="phone" label="Teléfono/Celular" />
      <TextInput validate={[email()]} source="email" label="Email" />
      <DateInput label="Fecha de Nacimiento" source="birthdate" />
      <SelectInput
        label="Género"
        source="gender"
        choices={[
          { id: "hombre", name: "Hombre" },
          { id: "mujer", name: "Mujer" },
        ]}
      />
      <ArrayInput
        source="addresses"
        label="Direcciones"
      >
        <SimpleFormIterator>
          <Map />
          <TextInput validate={[required()]} source="name" label="Nombre" />
          <TextInput validate={[required()]} source="street" label="Calle" />
          <TextInput source="house" label="Número de casa" />
          <TextInput source="reference" label="Referencia" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </StyledBaseCreate>
));
