import React from 'react';
import { styled } from '@mui/material/styles';
import { Toolbar, SaveButton, useNotify, TabbedForm, FormTab, NumberInput, SelectInput, Edit, TextInput, SimpleForm, required, ArrayInput, SimpleFormIterator, PasswordInput, TextField, ImageField, ImageInput } from 'react-admin';
const PREFIX = 'PasswordEdit';

const classes = {
    inlineBlock: `${PREFIX}-inlineBlock`
};

const StyledEdit = styled(Edit)({
    [`& .${classes.inlineBlock}`]: { display: 'inline-flex', marginRight: '1rem' },
});

const passwordsMustMatch = (value, allValues) => {
    console.log(allValues)
    return value !== allValues.new_password ? 'Contraseñas no coinciden' : undefined;
}

const validationPassword = [required(), passwordsMustMatch]

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const PasswordEdit = (({  ...props }) => {
    const notify = useNotify();
    const onSuccess = () => {
        notify('Contraseña cambiada exitosamente'); // default message is 'ra.notification.updated'
    }
    const onFailure = (error) => {
        notify('La contraseña ingresada no es correcta', 'warning')

    }

    return (
        <StyledEdit
            // I don't need any redirection here, there's no list page
            {...props}
            id={JSON.parse(localStorage.getItem('profile'))._id}
            resource="updatePassword"//"profile"
            basePath="/update-password"
            redirect={false}
            title="Cambiar Contraseña"
            undoable={false}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm toolbar={<EditToolbar />} >
                <FormTab label="Cambiar Contraseña">

                    <PasswordInput source="password" validate={required()} defaultValue="" label="Contraseña actual" />
                    <PasswordInput source="new_password" validate={validationPassword} label="Nueva contraseña" />
                    <PasswordInput source="new_password_confirm" validate={validationPassword} label="Confirmar nueva contraseña" />
                </FormTab>
            </TabbedForm>

        </StyledEdit>
    );
}
)

export default PasswordEdit;