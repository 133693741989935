import React from "react";
import {
  TextField,
  Edit as BaseEdit,
  SimpleForm,
  TextInput,
  DateField,
  SimpleShowLayout,
  NumberField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
} from "react-admin";

export const Edit = ({ classes, ...props }) => {
  return (
    <BaseEdit {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nombre" />
        <TextInput source="description" label="Descripcion" multiline />
        <SimpleShowLayout>
          <TextField source="code" label="Código" />
          <DateField source="expirationDate" label="Fecha de Expiracion" />
          <NumberField source="maxUses" label="Usos Máximos" />
          <NumberField source="usesLeft" label="Usos Restantes" />
          <TextField source="discountType" label="Tipo de Descuento" />
          <NumberField source="discount" label="Descuento" />
          <ReferenceArrayField
            source="validServices"
            label="Servicios Válidos"
            reference="service"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </SimpleShowLayout>
      </SimpleForm>
    </BaseEdit>
  );
};
