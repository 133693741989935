import React from "react";
import {
  List as BaseList,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from "react-admin";

export const List = (props) => (
  <BaseList {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <TextField source="code" label="Código" />
      <DateField source="expirationDate" label="Fecha de expiración" />
      <NumberField source="usesLeft" label="Usos restantes" />
      <NumberField source="maxUses" label="Usos máximos" />
      <TextField source="description" label="Descripción" />
      <TextField source="discountType" label="Tipo de descuento" />
      <NumberField source="discount" label="Descuento" />
      <ReferenceArrayField
        source="validServices"
        label="Servicios Válidos"
        reference="service"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </BaseList>
);
