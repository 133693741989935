import React from "react";
import { styled } from "@mui/material/styles";
import {
  ImageField,
  TextField,
  Edit as BaseEdit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  ImageInput,
  NumberInput,
  required,
} from "react-admin";
import { BooleanInputs } from "./Create";
const PREFIX = "Edit";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseEdit = styled(BaseEdit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

function formatImage(value) {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

export const Edit = ({ ...props }) => (
  <StyledBaseEdit {...props}>
    <SimpleForm>
      <TextField source="name" label="Nombre" />
      <ImageInput
        validate={[required()]}
        format={formatImage}
        source="imageURL"
        label="Imagen de Servicio"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="Imagen" />
      </ImageInput>
      <TextInput
        validate={[required()]}
        multiline
        source="description"
        label="Descripción"
      />
      <NumberInput
        validate={[required()]}
        source="unitPrice"
        placeholder="Bs."
        label="Precio Unitario"
      />

      <BooleanInputs />

      <NumberInput
        source="availableAfterHours"
        placeholder="horas"
        label="Tiempo de reserva anticipada (horas)"
        validate={[required()]}
        defaultValue={24}
      />

      <SelectInput
        validate={[required()]}
        source="unitEstimatedWorkMinutes"
        label="Tiempo de servicio estimado"
        choices={[
          { id: 0, name: "0 Minutos" },
          { id: 30, name: "30 Minutos" },
          { id: 60, name: "1 Hora" },
          { id: 90, name: "1 Hora 30 Minutos" },
          { id: 120, name: "2 Horas" },
          { id: 150, name: "2 Horas 30 Minutos" },
          { id: 180, name: "3 Horas" },
          { id: 210, name: "3 Horas 30 Minutos" },
          { id: 240, name: "4 Horas" },
          { id: 270, name: "4 Horas 30 Minutos" },
          { id: 300, name: "5 Horas" },
          { id: 330, name: "5 Horas 30 Minutos" },
          { id: 360, name: "6 Horas" },
          { id: 390, name: "6 Horas 30 Minutos" },
          { id: 420, name: "7 Horas" },
          { id: 450, name: "7 Horas 30 Minutos" },
          { id: 480, name: "8 Horas" },
        ]}
      />
      <SelectInput
        validate={[required()]}
        source="method"
        label="Método"
        choices={[
          { id: "En sucursal", name: "En sucursal" },
          { id: "A domicilio", name: "A domicilio" },
          { id: "Ambos", name: "Ambos" },
        ]}
      />
      <BooleanInput
        validate={[required()]}
        source="enabled"
        label="Habilitado"
      />
    </SimpleForm>
  </StyledBaseEdit>
);
