import React, { useState } from "react";
import {
  Create,
  required,
  Filter,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  ImageInput,
  PasswordInput,
  NumberInput,
  useGetList,
  usePermissions,
  SimpleShowLayout,
  useChoicesContext,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { Loading, Error } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { getFinalFor, getInitialFor } from "./availability";

const PREFIX = "AvailabilityEdit";
const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const UseDeliverySetting = () => {
  const { data, isLoading, error } = useGetList("deliverysetting");
  let [vehicle, setVehicle] = useState("");
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div>
      <SelectInput
        onChange={(e) => {
          setVehicle(e.target.value);
        }}
        source="deliveryVehicle"
        label="Vehículo"
        choices={data.map((e) => {
          let obj = JSON.stringify({ _id: e._id, title: e.title });
          return { id: obj, name: e.title };
        })}
      />
    </div>
  );
};

const EmployeeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="first_name" />
    <TextInput label="Apellido" source="last_name" />
  </Filter>
);

function formatLogo(value) {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

export const EmployeeList = (props) => {
  const { permissions } = usePermissions();
  return (
    <List filters={<EmployeeFilter />} {...props}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="first_name" label="Nombre" />
        <TextField source="last_name" label="Apellido" />
        <ImageField source="picture" label="Foto" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Teléfono" />
        <BooleanField source="state" label="Estado" />
        {permissions === "admin" && (
          <TextField source="deliveryVehicle.title" label="Vehículo" />
        )}
        <TextField source="serviceMethod" label="Método" />
        <TextField source="branch.name" label="Sucursal" />
      </Datagrid>
    </List>
  );
};

export const EmployeeEdit = (props) => {
  const { permissions } = usePermissions();
  return (
    <Edit {...props}>
      <SimpleForm>
        <ImageInput
          format={formatLogo}
          validate={[required()]}
          source="picture"
          label="Foto de Empleado"
          accept=".jpeg,.jpg,.png,.jfif"
          placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput validate={[required()]} source="first_name" label="Nombre" />
        <TextInput
          validate={[required()]}
          source="last_name"
          label="Apellido"
        />
        <BooleanInput validate={[required()]} source="state" label="Estado" />
        <NumberInput validate={[required()]} source="phone" label="Teléfono" />
        <SimpleShowLayout>
          <TextField validate={[required()]} source="email" />
          {permissions === "admin" && (
            <TextField source="deliveryVehicle.title" label="Vehículo" />
          )}
        </SimpleShowLayout>
        <ServiceSelection />
        {/* <SelectInput
          validate={[required()]}
          source="serviceMethod"
          label="Método de Servicio"
          choices={[
            { id: "En sucursal", name: "En sucursal" },
            { id: "A domicilio", name: "A domicilio" },
            { id: "Ambos", name: "Ambos" },
          ]}
        />

        <ReferenceArrayInput
          source="availableServices"
          reference="service"
          queryOptions={{ filter: { method: "serviceMethod" } }}
        >
          <SelectArrayInput label="Servicios Disponibles"  />
        </ReferenceArrayInput> */}

        <ArrayInput source="availability" label="Disponibilidad">
          <SimpleFormIterator>
            <SelectInput
              validate={[required()]}
              source="day"
              label="Día"
              choices={[
                { id: "L", name: "Lunes" },
                { id: "M", name: "Martes" },
                { id: "MM", name: "Miércoles" },
                { id: "J", name: "Jueves" },
                { id: "V", name: "Viernes" },
                { id: "S", name: "Sábado" },
                { id: "D", name: "Domingo" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("startHour")}
                  label="Hora de Inicio"
                  //  onChange={(e) => { console.log(e) }}
                  choices={getInitialFor(scopedFormData?.endHour)}
                />
              )}
            </FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="startMinute"
              label="Minuto de inicio"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("endHour")}
                  label="Hora Final" //formClassName={classes.inlineBlock}
                  choices={getFinalFor(scopedFormData?.startHour)}
                  formClassName={classes.inlineBlock}
                />
              )}
            </FormDataConsumer>
            {/* <SelectInput validate={[required()]} source="endHour" label="Hora Final" formClassName={classes.inlineBlock}
                            choices={finalHour} /> */}
            <SelectInput
              validate={[required()]}
              source="endMinute"
              label="Minuto final"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const EmployeeCreate = (props) => {
  const { permissions } = usePermissions();
  return (
    <Create {...props}>
      <SimpleForm>
        <ImageInput
          validate={[required()]}
          source="picture"
          label="Foto de Empleado"
          accept=".jpeg,.jpg,.png,.jfif"
          placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <TextInput validate={[required()]} source="first_name" label="Nombre" />
        <TextInput
          validate={[required()]}
          source="last_name"
          label="Apellido"
        />

        <TextInput validate={[required()]} source="email" label="Email" />
        <PasswordInput
          validate={[required()]}
          source="password"
          label="Contraseña"
        />
        <NumberInput validate={[required()]} source="phone" label="Teléfono" />
        <NumberInput validate={[required()]} source="CI_NIT" label="CI" />
        {permissions === "admin" && <UseDeliverySetting />}
        <ServiceSelection />
        <ArrayInput source="availability" label="Disponibilidad">
          <SimpleFormIterator>
            <SelectInput
              validate={[required()]}
              source="day"
              label="Día"
              choices={[
                { id: "L", name: "Lunes" },
                { id: "M", name: "Martes" },
                { id: "MM", name: "Miércoles" },
                { id: "J", name: "Jueves" },
                { id: "V", name: "Viernes" },
                { id: "S", name: "Sábado" },
                { id: "D", name: "Domingo" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("startHour")}
                  label="Hora de Inicio"
                  //  onChange={(e) => { console.log(e) }}
                  choices={getInitialFor(scopedFormData?.endHour)}
                />
              )}
            </FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="startMinute"
              label="Minuto de inicio"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("endHour")}
                  label="Hora Final" //formClassName={classes.inlineBlock}
                  choices={getFinalFor(scopedFormData?.startHour)}
                  formClassName={classes.inlineBlock}
                />
              )}
            </FormDataConsumer>
            {/* <SelectInput validate={[required()]} source="endHour" label="Hora Final" formClassName={classes.inlineBlock}
                            choices={finalHour} /> */}
            <SelectInput
              validate={[required()]}
              source="endMinute"
              label="Minuto final"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const SelectBranchInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const serviceMethod = useFormContext().watch("serviceMethod");
  return serviceMethod && serviceMethod !== "A domicilio" ? (
    <SelectInput
      {...props}
      label="Sucursal"
      optionText={(value) => {
        return value.name;
      }}
      optionValue="_id"
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
      validate={[required()]}
    />
  ) : (
    <React.Fragment />
  );
};

const ServiceSelection = () => {
  const form = useWatch("serviceMethod");

  React.useEffect(() => {
    console.log("method", form.serviceMethod);
  }, [form]);

  return (
    <>
      <SelectInput
        validate={[required()]}
        source="serviceMethod"
        label="Método de Servicio"
        choices={[
          { id: "En sucursal", name: "En sucursal" },
          { id: "A domicilio", name: "A domicilio" },
          { id: "Ambos", name: "Ambos" },
        ]}
      />
      <ReferenceInput
        label="Sucursal"
        source="branch"
        reference="enterprise/branches"
        filter={{
          status: true,
        }}
      >
        <SelectBranchInput />
      </ReferenceInput>

      <ReferenceArrayInput
        source="availableServices"
        reference="service"
        filter={{ method: form.serviceMethod }}
      >
        <SelectArrayInput label="Servicios Disponibles" />
      </ReferenceArrayInput>
    </>
  );
};
