import React, { useState } from "react";
import {
  List as BaseList,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  ExportButton,
  CreateButton,
  TopToolbar,
  useNotify,
  FilterButton,

} from "react-admin";
import RegexSearchInput from "../../components/RegexSearchInput";
import moment from "moment";
import DownloadCsvButton from "../../components/downloadCsv";
import { ImportButton } from "react-admin-import-csv";
import { Link } from "@mui/material";

const NameFilter = [
  <RegexSearchInput source="fullName" label="Nombre" />,
  <RegexSearchInput source="phone" label="Teléfono" />,
];

const ListActions = (props) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  const notify = useNotify();
  const [errors, setErrors] = useState([]);
  const errorsToString = (errors) => {
    return errors.reduce((acc, error) => {
      return `${acc ? acc + "\n" : ""}${error}`;
    }, "");
  };
  return (
    <TopToolbar className={className}>
      <FilterButton filters={NameFilter} />
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <ImportButton
        {...props}
        disableImportOverwrite={true}
        transformRows={(csvRows) => {
          return csvRows.map((row) => {
            const transformedRow = {};
            transformedRow.fullName = row.Nombre;
            if (row.Telefono) transformedRow.phone = row.Telefono;
            if (row.Email) transformedRow.email = row.Email;
            transformedRow.addresses = [];
            return transformedRow;
          });
        }}
        validateRow={async (csvRowItem, row) => {
          if (row === 0) {
            setErrors([]);
          }
          if (!csvRowItem.fullName) {
            errors.push(`Fila ${row + 1}: Nombre es requerido`);
            notify(errorsToString(errors), { type: "error", multiLine: true });
            throw new Error("Nombre es requerido");
          }
          if (csvRowItem.phone && isNaN(csvRowItem.phone)) {
            errors.push(`Fila ${row + 1}: Telefono debe ser un numero`);
            notify(errorsToString(errors), { type: "error", multiLine: true });
            throw new Error("Telefono debe ser un numero");
          }
          if (
            csvRowItem.email &&
            !csvRowItem.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
          ) {
            errors.push(`Fila ${row + 1}: Email invalido`);
            notify(errorsToString(errors), { type: "error", multiLine: true });
            throw new Error("Email invalido");
          }
        }}
      />
      <DownloadCsvButton />
    </TopToolbar>
  );
};

export const List = (props) => (
  <BaseList
    {...props}
    filters={NameFilter}
    bulkActionButtons={false}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="fullName" label="Nombre Completo" />
      <TextField source="phone" label="Teléfono" />
      <TextField source="email" label="Email" />
      <TextField source="gender" label="Género" />
      <DateField source="birthdate" label="Fecha de Nacimiento" />
      <FunctionField
        label="Fecha de Ultimo servicio"
        sortBy="lastBooking.bookingDate"
        render={(record) => {
          const date = record.lastBooking
            ? moment(record.lastBooking.bookingDate)
            : null;
          return record.lastBooking?.bookingDate ? (
            <Link href={"#/booking/" + record.lastBooking._id + "/show"}>{date.format("DD/MM/YYYY")}</Link>
          ) : (
            ""
          );
        }}
      />
      <FunctionField
        label="Dias desde el ultimo servicio"
        sortBy="lastBooking.bookingDate"
        render={(record) => {
          const diff = record.lastBooking
            ? moment().diff(moment(record.lastBooking.bookingDate), "days")
            : null;
          return record.lastBooking?.bookingDate ? diff : "";
        }}
      />
    </Datagrid>
  </BaseList>
);
